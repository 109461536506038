.footer {
  background-color: #00000070;
  min-height: 40px;
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.footer > code {
  font-size: 11px;
  margin-right: 10px;
}

.footer a {
  font-weight: bold;
}