nav {
  background-color: #00000040;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

nav .navButton,
nav .disabledButton {
  background-color: #00000050;
  display: flex;
  padding: 0 20px 0 20px;
  height: 100%;
  min-height: 40px;
  border: 1px solid grey;
  border-radius: 15px 15px 0 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

nav .disabledButton {
  pointer-events: none;
}

nav .navButton.active {
  background-color: #1b4f7270;;
}

nav .navButton:hover {
  cursor: pointer;
  opacity: 0.75;
}