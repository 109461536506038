.layout {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.layout > * {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}