.portfolio-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.vert-line::before {
  background: #00000040 !important;
}

.vertical-timeline-element-icon > svg {
  width: 85% !important;
  height: 85% !important;
  left: 27% !important;
  top: 27% !important;
  /* left: 55% !important;
  transform: scale( 2 ); */
}

.card {
  background-color: #FFFFFF90;
  border-radius: 5px;
  margin: 25px ;
  display: flex;
  align-content: stretch;
  flex-basis: 20%;
  transition: scale 1s;
}

.card:hover {
  /* cursor: pointer; */
  transform: scale( 1.15 );
  z-index: 5;
}

/* .card:hover .thumbnail {
  transform: scale( 1.15 );
  z-index: 5;
} */

.thumbnail {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 1s;
}

.thumbnail img {
  max-height: 100%;
  max-width: 100%;
}

.title-summary {
  text-align: center;
  background-color: #00000080;
  border-radius: 5px;
  margin-top: 10px;
}
